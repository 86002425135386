.setup {
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    padding-bottom: 10px;
    background: rgba(254, 250, 224, .7);
    border-radius: 12%;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px;
}

p {
    font-family: 'Lora';
    color: #283618;
}

.form-control {
    font-family: 'Lora';
    color: #283618;
}

.playersContainer {
    font-family: 'Lora';
    color: #283618;
    display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
        margin-bottom: 20px;
}

.buttonsContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.submit {
    background-color: #283618;
    color: #fefae0 ;
    border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        margin-left: 10px;
        margin-top: 20px;
}

.nineHoleButton {
    background-color: #283618;
    color: #fefae0 ;
    border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        margin-left: 10px;
}

.eighteenHoleButton {
    background-color: #283618;
    color: #fefae0 ;
    border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        margin-left: 10px;
}

.chipSelectionButton {
    background-color: #283618;
    color: #fefae0 ;
    border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        margin-left: 10px;
}

.addedPlayer {
    border: solid;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    min-width: fit-content;
    background-color: white;
    padding: 10px;
    box-shadow: 0 0 50px #ccc;
}

.selectedChips {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.specificChipSetup {
    border: solid;
    border-radius: 50%;
    min-width: fit-content;
    background-color: white;
    padding: 10px;
    align-self: center;
}

@media only screen 
and (max-width: 390px) {
    .submit {
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        width: fit-content;
        align-self: center;
        margin-left: 10px;
    }
    
    .playerForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .nineHoleButton {
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
    }

    .eighteenHoleButton {
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
    }

    .buttonsContainer {
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
    }

    .playersContainer {
        display: flex;
        justify-content: space-evenly;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .addedPlayer {
        border: solid;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        min-width: fit-content;
        background-color: white;
        padding: 10px;
        box-shadow: 0 0 50px #ccc;
    }
}