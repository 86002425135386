.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.appOverlay {
  min-height: 100vh;
  background: url("./assets/westwoods3.jpg") no-repeat;
  filter: brightness(30%);
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.appContainer {
  width: fit-content;
  height: fit-content;
  align-self: center;
  z-index: 10;
  margin-top: 35px;
}

.westeeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  margin-left: 10px;
  margin-right: 10px;
}

.westeeButton {
  align-self: center;
  border: none;
  background: transparent;
  width: 80px;
  height: auto;
}

.westeeButtonLogo {
  max-width: 100%;
}

.westeeTag span {
  display: none;
}

.tees {
  font-size: small;
  margin: 0;
  margin-top: -5px;
}

.westee_tee_wood{
  background: url("./assets/westee_tee_wood.png") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin-top: -3px;
}

.westee_tee_blue {
  background: url("./assets/westee_tee_blue.png") no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.westee_tee_black {
  background: url("./assets/westee_tee_black.png") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin-top: -10px;
}

.westee_tee_black, .westee_tee_blue, .westee_tee_wood {
  min-width: 75px;
  min-height: 60px;
  align-self: center;
  border: none;
}

body {
  min-height: 100vh;
}

.titleContainer{
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  margin-top: -15px;
  margin-bottom: -35px;
}

h1 {
  font-family: 'Lora';
  font-size: 30px;
  text-decoration: underline;
  color: #E1DCD1;
}

h2 {
  font-family: 'Lora';
  text-decoration: underline;
  color: #E1DCD1;
}

h3 {
  font-family: 'Lora';
  color: #E1DCD1;
}

.title {
  margin-top: 30px;
}

@media only screen 
and (max-width: 390px)  {
  /* .titleContainer {
    font-size: 35px;
  } */

  .appContainer {
    padding-left: 15px;
    padding-right:15px;
    margin-top: 35px;
  }

  .westeeButton {
    max-width: 73px;
  }
}

@media only screen 
and (max-width: 320px)  {
  /* .titleContainer {
    font-size: 30px;
  } */
}