.assignPosChip, .assignNegChip, .assignChip {
     background-color: rgba(254, 250, 224, .7);
     color: #000000 ;
     font-family: 'Lora';
     border-radius:10px;
     min-height:30px; 
     min-width: 120px;
     align-self: center;
     cursor: pointer;
}


.assignPosChip.active {
     background-color: #678d3c;
     color: #fefae0 ;
}

.assignNegChip.active {
     background-color: rgba(231, 83, 83, 0.7);
     color: #fefae0 ;
}

.assignChip.active {
     background-color: #000000;
     color: #E1DCD1;
}


.navBackContainer {
 display: flex;
 justify-content: center;
 margin-top: 15px;
 cursor: pointer;
}

.navBack {
     background-color: #E1DCD1;
     color: #000000;
     font-family: 'Lora';
     border-radius:10px;
     box-shadow: 0px 0px 2px 2px rgb(0,0,0);
     min-height:30px; 
     min-width: 120px;
}

.specificPosChip, .specificNegChip, .specificChip {
     border: solid;
     border-radius: 50%;
     width: 50px;
     min-width: fit-content;
     background-color: white;
     padding: 10px;
     align-self: center;
}


.allChipContainer {
     display: grid;
     gap: 8px;
     grid-template-columns: repeat(3, 1fr);
     grid-auto-flow: dense;
}

.chipContainer {
     display: flex;
     flex-direction: column;
     padding-bottom: 15px;
}

@media only screen 
and (max-width: 390px) {
   .assignPosChip, .assignNegChip {
        background-color: rgba(254, 250, 224, .7);;
        color: #000000 ;
        font-family: 'Lora';
        border-radius:10px;
        min-height:30px; 
        min-width: 120px;
        align-self: center;
   }
}