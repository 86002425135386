.instructionsContainer {
    margin-bottom: auto;
    background-color: rgba(254, 250, 224, .7);
    border-radius: 12%;
}

.homepageButtons {
    display: flex;
    justify-content: space-evenly;
    z-index: 1;
}

.howToPlayButton, .playButton {
    font-family: 'Lora';
    background-color: #E1DCD1;
    color: #000000 ;
    border-radius:10px;
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    min-height:30px; 
    min-width: 120px;
    margin: 15px;
}



@media only screen 
and (max-width: 390px)  {
    .instructionsContainer {
        margin: 25px;
        margin-bottom: auto;
    }
      
}


