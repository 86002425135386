.nineHoleContainer {
    display: flex;
    flex-direction: column;
    font-family: 'Lora';
}

.holePlayerInformation {
    background: rgba(254, 250, 224, .7);
    min-width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 3px;
    padding: 13px;
    border-radius: 12%;
}

.nineHole {
    display: flex;
    flex-direction: column;
}

.currentChipsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1px;
    margin-left: 10px;
}

.holePlayerChips {
    border: solid;
    border-radius: 50%;
    width: 50px;
    min-width: fit-content;
    background-color: white;
    padding: 10px;
    box-shadow: 0 0 50px #ccc;
    align-self: center;
}

.removeChip {
    align-self: center;
    background-color: #283618;
    color: #fefae0 ;
    font-family: 'Lora';
    border-radius:10px;
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    min-height:fit-content;
    min-width: fit-content;
}

.holePlayerName {
    text-decoration: underline;
    align-self: flex-start;
    width: min-content;
    margin-right: auto;
}

.holeButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
}

.back-button, .allDoneButton {
    background-color: #E1DCD1;
    color: #000000 ;
    font-family: 'Lora';
    border-radius:10px;
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    min-height:fit-content;
    min-width: fit-content;
}


@media only screen 
and (max-width: 390px) {
    .nineHoleContainer {
        display: flex;
        flex-direction: column;
        font-family: 'Lora';
    }

    .holePlayerInformation {
        border: 2px solid;
        border-color: #283618;
        min-width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 3px;
        padding: 13px;
    }

    .nineHole {
        display: flex;
        flex-direction: column;
    }

    .currentChipsContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1px;
        margin-left: 10px;
    }

    .holePlayerChips {
        border: solid;
        border-radius: 50%;
        width: 50px;
        min-width: fit-content;
        background-color: white;
        padding: 10px;
        box-shadow: 0 0 50px #ccc;
        align-self: center;
    }

    .removeChip {
        align-self: center;
        background-color: #283618;
        color: #fefae0 ;
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        cursor: pointer;
    }

    .holePlayerName {
        text-decoration: underline;
        align-self: flex-start;
        width: min-content;
        margin-right: initial;
    }

    .holeButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 20px;
    }

    .back-button {
        background-color: #283618;
        color: #fefae0 ;
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        cursor: pointer;
    }

    .allDoneButton {
        background-color: #283618;
        color: #fefae0 ;
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        cursor: pointer;
    }

}