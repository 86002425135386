.scoreCardContainer {
    display: flex;
    flex-direction: column;
    background-color: rgba(254, 250, 224, .7);
    border-radius: 10%;
    margin-bottom: 15px;
    width: 150px;
  }

  .playerName {
      margin: 5px;
      color: #000000;
  }

  .currentHole {
      font-family: 'Lora';
      border: solid #283618;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      margin: 5px;
  }
  
  .enterScore {
      background-color: #283618;
      color: #fefae0 ;
      font-family: 'Lora';
      border-radius:10px;
      box-shadow: 0px 0px 2px 2px rgb(0,0,0);
      min-height:fit-content;
      min-width: fit-content;
      align-self: center;
      margin: 10px;
  }

  .addChips {
      background-color: #283618;
      color: #fefae0 ;
      font-family: 'Lora';
      border-radius:10px;
      box-shadow: 0px 0px 2px 2px rgb(0,0,0);
      min-height:fit-content;
      min-width: fit-content;
      cursor: pointer;
  }

  .scoreForm {
      display: flex;
      flex-direction: column;
  }
  
  label {
      font-family: 'Lora';
  }

  a{
      align-self: center;
      margin: 10px;
  }

  .scoreInput {
      align-self: center;
  }

  .scoreContainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
  }

  .nextHoleButton, .previousHoleButton {
        background-color: #E1DCD1;
        color: #000000 ;
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        align-self: center;
        margin: 10px;
        cursor: pointer;
  }

  .nextHoleButton:disabled, .previousHoleButton:disabled {
    text-decoration: line-through;
  }

    .holeMovementContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 20px;
        margin-bottom: 20px;
  }


@media only screen 
and (max-width: 390px) {
    .scoreCardContainer {
      display: flex;
      flex-direction: column;
      border-color: #283618;
      background-color: rgba(254, 250, 224, .7);
      margin-bottom: 15px;
      width: fit-content;
    }

    .playerName {
        margin: 3px;
    }

    .currentHole {
        font-family: 'Lora';
        border: solid #283618;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        margin: 5px;
    }
    
    .enterScore {
        background-color: #283618;
        color: #fefae0 ;
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        align-self: center;
        margin: 10px;
    }

    .addChips {
        background-color: #283618;
        color: #fefae0 ;
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
    }

    .scoreForm {
        display: flex;
        flex-direction: column;
    }
    
    label {
        font-family: 'Lora';
    }

    a{
        align-self: center;
        margin: 10px;
    }

    .scoreInput {
        align-self: center;
    }

    .scoreContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

@media only screen 
and (max-width: 500px) {
    .scoreCardContainer {
      width: fit-content;
    }
}

@media only screen 
and (max-width: 623px) {
    .scoreCardContainer {
        width:  fit-content;
    }
}

@media only screen 
and (max-width: 633px) {
    .scoreCardContainer {
        width:  130px;
    }
}

@media only screen 
and (max-width: 545px) {
    .scoreCardContainer {
        width:  110px;
    }
}

@media only screen 
and (max-width: 463px) {
    .scoreCardContainer {
        width:  fit-content;
    }
}