.endRound {
    align-self: center;
    background-color: #E1DCD1;
    color: #000000 ;
    font-family: 'Lora';
    border-radius:10px;
    box-shadow: 0px 0px 2px 2px rgb(0,0,0);
    min-height:fit-content;
    min-width: fit-content;
    margin-top: 15px;
    margin-bottom: 15px;
}

.winningsContainer {
    border: 2px solid;
    border-radius: 10%;
    margin: 3px;
    width: fit-content;
    align-self: center;
    background-color: #e1b17a;
    padding: 10px;
}

.currentTotalScore {
    margin-left: 10px;
}

.finishContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.indMessage {
    color: #283618;
    font-weight: bold;
}


@media only screen 
and (max-width: 390px) {
    .endRound {
        align-self: center;
        background-color: #E1DCD1;
        color: #000000 ;
        font-family: 'Lora';
        border-radius:10px;
        box-shadow: 0px 0px 2px 2px rgb(0,0,0);
        min-height:fit-content;
        min-width: fit-content;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}